
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300;400;700&display=swap');

body{
  font-family: 'IBM Plex Sans Arabic', sans-serif !important;
}

*{
  margin: 0;
  box-sizing: border-box;
}

/* login */
.login {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 12rem auto;
  padding: 2rem ;
  border-radius: 8px;

}

.login form {
  width: 400px;
}

.login input {
  text-align: right;
  margin-top: 5px;
}
.login .top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login .button {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
}

/* header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* navbar */
.navbar {
  display: flex;
  flex-direction: column;
}

.navbar a {
  text-decoration: none;
}

.navbar span {
  font-size: 1.2rem;
}


/* loading */
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: #00000099;
  width: 100%;
  height: 100vh;
  z-index: 9999;
}


.imgFromServer {
  width: 100%;
  height: 100%;
  height: 250px;
  max-height: 250px;
  object-fit: cover;
}

.operations {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: blue;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}



@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}